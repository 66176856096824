<template>
  <div class="head-bar-container" @click.self="closeMenu">
    <div class="head-bar-row pc_padding">
      <div class="head-bar-name-row cursor" @click="gohome">
        <div class="head-bar-name">
          {{ title }}
        </div>
      </div>
      <div class="head-bar-btns-row">
        <div v-if="domainConfig?.novel == 1" class="head-bar-novel-btn cursor" @click="gotoNovel">
          <img src="@/assets/novel-icon.png" alt=""> <span
            v-if="deviceType != 'mobile'">Novel</span>
        </div>
        <div class="head-bar-hamber-row cursor" @click="toggleOpenState">
          <img src="@/assets/menu.png" alt="">
        </div>
      </div>
    </div>

    <div class="menu" :class="{ 'menu-open': isOpen }">
      <div class="menu-title">
        Resources
      </div>
      <ul>
        <li class="cursor" @click="goto('/')">
          <a>Home</a>
          <div class="arrow" />
        </li>
        <li class="cursor" @click="goto('/terms-of-services')">
          <a>Terms of services</a>
          <div class="arrow" />
        </li>
        <li class="cursor" @click="goto('/privacy-policy')">
          <a>Privacy policy</a>
          <div class="arrow" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    title: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      isOpen: false,
    }
  },
  computed: {
    ...mapGetters([
      'deviceType',
      'domainConfig'
    ])
  },
  mounted () {
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeDestroy () {
    document.removeEventListener('click', this.handleClickOutside);
  },
  methods: {
    gohome () {
      // window.location.href = '/'
      const currentParams = this.$router.currentRoute.params; // 当前路由的 params
      const currentQuery = this.$route.query; // 当前路由的 query

      const channel = currentParams.channel ? `/${currentParams.channel}` : ''; // 拼接 channel path
      const queryString = new URLSearchParams(currentQuery).toString(); // 转换 query 对象为字符串
      const fullQueryString = queryString ? `?${queryString}` : ''; // 如果有查询参数，拼接为 ?xxx=yyy 格式
      console.log(channel);
      console.log(fullQueryString);

      window.location.href = `${channel}/${fullQueryString}`;
    },
    gotoNovel () {
      const currentParams = this.$router.currentRoute.params; // 当前路由的 params
      const currentQuery = this.$route.query; // 当前路由的 query

      const channel = currentParams.channel ? `/${currentParams.channel}` : ''; // 拼接 channel path
      const queryString = new URLSearchParams(currentQuery).toString(); // 转换 query 对象为字符串
      const fullQueryString = queryString ? `?${queryString}` : ''; // 如果有查询参数，拼接为 ?xxx=yyy 格式

      window.location.href = `${channel}/novel${fullQueryString}`;
    },
    toggleOpenState () {
      this.isOpen = !this.isOpen;
    },
    handleClickOutside (event) {
      if (this.isOpen && !this.$el.contains(event.target)) {
        this.isOpen = false;
      }
    },
    closeMenu () {
      if (this.isOpen) {
        this.isOpen = false;
      }
    },
    goto (path) {
      console.log(this.$route.path);
      this.isOpen = false;
      if (path == '/') {
        this.$emit('setRequestDomainHome');
      }
      if (path == '/' && this.$route.path == '/') {
        this.scrollToTop()
        return
      }
      // this.$router.push({ name: path });
      const currentParams = this.$router.currentRoute.params; // 当前路由的 params
      const currentQuery = this.$route.query; // 当前路由的 query

      const channel = currentParams.channel ? `/${currentParams.channel}` : ''; // 拼接 channel path
      const queryString = new URLSearchParams(currentQuery).toString(); // 转换 query 对象为字符串
      const fullQueryString = queryString ? `?${queryString}` : ''; // 如果有查询参数，拼接为 ?xxx=yyy 格式

      window.location.href = `${channel}${path}${fullQueryString}`;
    },
  },
}
</script>
